import { NewPasswordInput } from "../../components/newPasswordInput/newPasswordInput.tsx";
import { Button, Grid, Heading, Input, Text } from "@idg-web-platform/pulse";
import css from "./registration.module.css";
import * as Auth from "@aws-amplify/auth";
import { Privacy } from "./privacy.tsx";
import { login } from "../../config/cognito.ts";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect, useRef, useState } from "preact/hooks";
import { validateEmail } from "../../functions/validateFormFields.ts";
import analytics from "@idg-data-analytics-platform/analytics";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";

interface LoginDataPageProps {
  successRedirect: string;
  cancelRedirect?: string;
}

interface LoginDataPageState {
  signingUp?: boolean;
  errorNotification?: string;
  isEmailValid?: boolean;
  emailValue?: string;
  isPasswordValid?: boolean;
  passwordValue?: string;
  isFormValid?: boolean;
}

LoginDataPage.tagName = "control-login-data-page";

export function LoginDataPage({ successRedirect, cancelRedirect }: LoginDataPageProps) {
  const [state, setState] = useState<LoginDataPageState>({});
  const passwordInputRef = useRef<NewPasswordInput>(null);
  const { alert, showAlert, closeAlert } = useManagedAlert();

  useEffect(() => {
    analyticsContentViewed("login_data");
  }, []);

  function updateFormValidity(stateChange: Partial<LoginDataPageState>) {
    function isStateValid(state: LoginDataPageState) {
      return state.isEmailValid && state.isPasswordValid;
    }

    setState((state) => {
      const newState = { ...state, ...stateChange, isFormValid: false };
      return { ...newState, isFormValid: isStateValid(newState) };
    });
    closeAlert();
  }

  function onEmailInputChange(emailValue: string) {
    updateFormValidity({
      isEmailValid: validateEmail(emailValue),
      emailValue: emailValue.trim().toLowerCase(),
    });
  }

  function onNewPasswordInputChange(isPasswordValid?: boolean, passwordValue?: string) {
    updateFormValidity({ isPasswordValid, passwordValue });
  }

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (state.emailValue === undefined) {
      state.emailValue = "";
      state.isEmailValid = false;
    }

    passwordInputRef.current?.forceValidate().then(() => {
      if (!state.isFormValid) {
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Bitte überprüfe deine Eingaben.",
          severity: "error",
        });
      } else {
        closeAlert();
        signUp();
      }
    });
  }

  function signUp() {
    const email = state.emailValue!;
    const password = state.passwordValue!;

    setState((state) => ({ ...state, signingUp: true }));

    const handleError = (err: Error) => {
      if (
        err.name === "UsernameExistsException" ||
        (err.name === "UserLambdaValidationException" &&
          err.message.includes("User already exists"))
      ) {
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Für diese E-Mail-Adresse besteht bereits ein Benutzerkonto.",
          severity: "error",
        });
      } else if (err.name === "UserAlreadyAuthenticatedException") {
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description:
            "Du bist bereits angemeldet. Bitte logge dich aus, um ein neues Konto zu erstellen.",
          severity: "error",
        });
      } else if (err.name === "EmptySignUpPassword") {
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Bitte gib ein Passwort ein.",
          severity: "error",
        });
      } else {
        // log only unexpected errors
        console.error(err);

        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Das hat leider nicht geklappt. Bitte versuche es später erneut.",
          severity: "error",
        });
      }
      setState((state) => ({ ...state, signingUp: false }));
    };

    Auth.signUp({
      username: email,
      password: password,
      options: {
        userAttributes: {
          email: email,
        },
      },
    })
      .then(() => {
        analytics({
          event: "generic_registration_successful",
          team: "control",
        });
        login(email, password, successRedirect).catch(handleError);
      })
      .catch(handleError);
  }

  function onAbort(e: Event, redirect?: string) {
    e.preventDefault();
    if (redirect) window.open(redirect, "_self");
    else history.back();
  }

  return (
    <>
      <Grid>
        <div className={css.registrationPageContainer}>
          <div className={css.headingContainer}>
            <Heading sizeStep={2} removeMargin={true} element="h1" text="Registrierung" />
          </div>

          <div className={css.subHeadlineContainer}>
            <Text fontStyle={"bold"} sizeStep={0} removeMargin={true}>
              Werde CLUB Member und registriere dich für dein persönliches Kundenkonto.
            </Text>
          </div>

          <form className={css.registrationForm} onSubmit={onSubmit} noValidate>
            <Input
              label="E-Mail-Adresse"
              type="email"
              id="email"
              autocomplete="email"
              valid={state.isEmailValid}
              onInput={(e) => onEmailInputChange(e.currentTarget.value)}
              errorMessage={"Bitte gib eine gültige E-Mail-Adresse ein"}
              required
            />
            <NewPasswordInput
              ref={passwordInputRef}
              label="Passwort"
              id="password"
              onChange={onNewPasswordInputChange}
            />

            <Text fontStyle={"bold"} sizeStep={-1} removeMargin={true}>
              Immer auf dem Laufenden:
            </Text>

            <Text sizeStep={-1}>
              Ab jetzt bekommst du von uns und deinem Lieblingsstore alle Infos rund um deine CLUB
              Vorteile per Post und <span style="word-break: keep-all;">E-Mail</span>.
            </Text>

            <Text fontStyle={"bold"} sizeStep={-1} removeMargin={true}>
              Hinweis:
            </Text>

            <Text sizeStep={-1}>
              Solltest du schon Club-Mitglied sein, beachte bitte, dass es aktuell nicht möglich
              ist, deine bereits vorhandene Club-Karte in deinem Intersport.de-Kundenkonto zu
              hinterlegen. Wir arbeiten daran.
            </Text>

            <Privacy />

            <ManagedAlert alert={alert} onClose={closeAlert} />

            <Button
              class={css.registrationButton}
              text="Speichern und weiter"
              level="primary"
              type="submit"
              isLoading={state.signingUp}
            />
            <Button
              class={css.registrationButton}
              text="Abbrechen"
              level="secondary"
              onClick={(e) => onAbort(e, cancelRedirect)}
            />
          </form>
        </div>
      </Grid>
    </>
  );
}
